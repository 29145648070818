<template lang="pug">
div
	docs(:data="endpoint")
</template>
<script>

import { defineComponent } from 'vue';
import Docs from '@/components/docs/Docs.vue';
import {CLICK_URL_ENDPOINT} from '@/components/docs/constants';

export default defineComponent({
	components: {Docs},
	data() {
		return {
			endpoint: CLICK_URL_ENDPOINT
		}
	}
})
</script>
