export const GET_OFFERS_ENDPOINT = {
    method: 'GET',
    endpoint: '/api/v2.0/getOffers',
    request: [
        {property: 'api_key', required: true, default: null, description: "Token for authorization"},
        {property: 'limit', required: false, default: 50, description: "Amount of returned records"},
        {property: 'page', required: false, default: 1, description: "Page offset"},
        {property: 'keyword', required: false, default: null, description: "Search keyword. If string -> search matches by offer name. If integer -> search matches for both offer name and id"},
    ],
    response: [
        {property: 'id', type: 'integer', description: 'offer id'},
        {property: 'name', type: 'string', description: 'offer name'},
        {property: 'payout', type: 'float', description: 'payout'},
        {property: 'currency', type: 'string', description: 'currency'},
        {property: 'is_vta', type: 'boolean', description: 'is VTA'},
        {property: 'platform', type: "enum('android', 'ios', 'web', 'all')", description: 'platform type'},
        {property: 'preview_url', type: 'string', description: 'app url'},
        {property: 'kpi', type: 'string', description: 'kpi'},
        {property: 'description', type: 'string', description: 'text description'},
        {property: 'kpi', type: 'string', description: 'kpi type'},
        {property: 'events', type: 'Array<Event>', description: 'array of conversion events'},
        {property: 'Event.name', type: 'string', description: 'name of the event'},
        {property: 'Event.type', type: 'string', description: 'type of the event'},
        {property: 'daily_click_cap', type: 'integer', description: 'daily click cap'},
        {property: 'daily_impression_cap', type: 'integer', description: 'daily impression cap'},
        {property: 'click_url', type: 'string', description: 'click url'},
        {property: 'impression_url', type: 'string', description: 'impression url'},
        {property: 'targeting', type: 'Object', description: 'location targeting'},
        {property: 'targeting.countries', type: 'Object', description: 'location targeting for countries'},
        {property: 'targeting.countries.whitelist', type: 'Array', description: 'allowed countries'},
        {property: 'targeting.countries.blacklist', type: 'Array', description: 'not allowed countries'},
        {property: 'event_managed', type: 'boolean', description: 'If offer conversion is CPA'},
        {property: 'default_placement_payout', type: 'float', description: 'default payout'},
        {property: 'payout_type', type: "enum('CPA', 'CPI', 'CPC', 'CPM')", description: 'default payout'},
        {property: 'kpi_value', type: 'integer', description: 'kpi value'},
        {property: 'bundle_id', type: 'string', description: 'bundle id'},
        {property: 'package_id', type: 'integer | string', description: 'package id'},
        {property: 'daily_cap', type: 'integer', description: 'daily conversions cap'},
        {property: 'hard_kpi', type: 'integer', description: ''},
        {property: 'white_list', type: 'Array', description: ''},
        {property: 'black_list', type: 'Array', description: ''},

    ]
};

export const CLICK_URL_ENDPOINT = {
    method: "GET",
    endpoint: "https://ila3.co/o/{campaign_id}?p={pid}&aff_clickid={clickid}&sub1={sub1}",
    request: [
        {property: 'campaign_id', required: true, default: "provided value from our side", description: "Provided value from our side"},
        {property: 'p', required: true, default: "provided value from our side", description: "Provided value from our side"},
        {property: 'aff_clickid', required: true, default: null, description: "Click id"},
        {property: 'sub1', required: true, default: null, description: "Subsource"},
        {property: 'sub2', required: false, default: null, description: "Subsource"},
        {property: 'ssp', required: false, default: null, description: "SSP"},
        {property: 'appname OR app_name', required: false, default: null, description: "app name"},
        {property: 't OR tag OR tid', required: false, default: null, description: "tag id"},
        {property: 'bundle OR bundleid OR bundle_id', required: false, default: null, description: "bundle id"},
        {property: 'device_id', required: false, default: null, description: "Device ID"},
        {property: 'os_version', required: false, default: null, description: "OS version"},
        {property: 'device_model', required: false, default: null, description: "device model"},
    ],
    response: [],
    note: "Domain and path may differ."
}