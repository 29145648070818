<template lang="pug">
.card.card-bordered.mb-5
    .card-body
        .request-heading.mb-10
            div.badge.badge-light-primary.fs-6.text-uppercase {{ data.method }}
            code {{ data.endpoint }}
        .request-heading.mb-10(v-if="data.note")
            div.badge.badge-danger.fs-8.text-uppercase NOTE:
            code.fs-8 {{ data.note }}
        .request-wrapper.mb-5
            h4 Request
            .table-responsive
                table.table.gs-7.gy-7.gx-7
                    thead
                        tr.fw-semibold.fs-6.text-gray-800.border-bottom.border-gray-200
                            th Property
                            th Required
                            th Default
                            th Description
                    tbody
                        tr(v-for="record in data.request")
                            td {{ record.property }}
                            td
                                .badge.badge-success(v-if="record.required") Yes
                                .badge.badge-danger(v-else) No
                            td
                                span(v-if="record.default") {{ record.default }}
                                span(v-else) No default value
                            td {{ record.description }}
        .response-wrapper.mb-5
            h4 Response
            code 
                pre {{ interfaceObject }}
            .table-responsive
                table.table.gs-7.gy-7.gx-7
                    thead
                        tr.fw-semibold.fs-6.text-gray-800.border-bottom.border-gray-200
                            th Property
                            th Type
                            th Description
                    tbody
                        tr(v-for="record in data.response")
                            td {{ record.property }}
                            td {{ record.type }}
                            td {{ record.description }}

</template>
<script>

import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        data: {
            default: () => ({
                method: null,
                endpoint: null,
                request: [],
                response: []
            }),
            required: true
        }
    },
    setup(props) {
        const data = ref(props.data);
        console.log("RESP:", data.value.response);
        const buildObject = () => {
            const resp = data.value.response;
            const obj = {};
            for(let record of resp) {
                obj[record.property] = record.type;
            }
            return obj;
        };
        const interfaceObject = buildObject();
        console.log("interface:", interfaceObject);
        return {interfaceObject};
    },
})
</script>
